body {
    // Default heights
    --header-cmstoolbar-height: 0px;
    --header-leaderboard-height: 0px;
    --header-leaderboard-content-height: calc(auto);
    --header-leaderboard-content-last-height: calc(auto);
    --header-main-height: 0px;
}

html.scripted {
    // Convert cms toolbar from fixed to sticky
    &.cms-toolbar-expanded {
        margin-top: 0 !important;
    }

    div.cms {
        position: sticky;
        z-index: 9999;
    }
    div.cms .cms-toolbar {
        position: relative !important;
    }

    // Make other header els sticky
    #leaderboardAd {
        position: sticky;
        top: var(--header-cmstoolbar-height);
        height: var(--header-leaderboard-content-height);
        min-height: var(--header-leaderboard-content-last-height);
        z-index: 9998;
        transition:
            height 0.3s ease,
            min-height 0.3s ease;
        overflow: hidden;
    }

    header.main {
        position: sticky;
        top: calc(
            var(--header-cmstoolbar-height) + var(--header-leaderboard-height)
        );
        z-index: 9998;
    }

    // Transition allows leaderboard to slide out of position
    body.leaderboard-transition {
        #leaderboardAd,
        header.main {
            transition:
                height 0.3s ease,
                min-height 0.3s ease,
                top 0.3s ease;
        }
        #leaderboardAd {
            top: calc(
                (
                        var(--header-leaderboard-height) - var(
                                --header-cmstoolbar-height
                            )
                    ) * -1
            );
        }

        header.main {
            top: var(--header-cmstoolbar-height);
        }
    }

    // Leaderboard in final position
    body.leaderboard-lock {
        #leaderboardAd {
            position: relative;
            top: auto;
        }

        header.main {
            top: var(--header-cmstoolbar-height);
        }
    }
}

header.main {
    width: 100%;

    .headerBar {
        background: $black;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 80px 1fr 2fr;
        align-items: center;

        @media (max-width: 850px) {
            grid-template-columns: 65px 1fr 3fr;
        }

        @media (max-width: 600px) {
            grid-template-columns: 65px 1fr 3fr;
        }

        @media (max-width: 450px) {
            display: flex;
            flex-wrap: wrap;
        }

        .headerBaritem {
            padding: 15px 0;
            font-family: $proxima;
            font-weight: 700;
            font-size: 0.95rem;
            color: $white;

            @media (max-width: 600px) {
                padding: 5px 0;
            }

            @media (max-width: 450px) {
                min-width: 0;
            }

            &:last-of-type {
                text-align: right;
                padding: 0;

                @media (max-width: 450px) {
                    margin-left: auto;
                }
            }

            &.logo {
                position: relative;
                top: 2px;

                @media (max-width: 600px) {
                    padding: 5px 0 0;
                }

                a img {
                    @media (max-width: 450px) {
                        width: 200px;
                    }

                    @media (max-width: 350px) {
                        width: 170px;
                    }
                }
            }
            span.shop-btn,
            span.newsletters-btn,
            span.renew-btn-alternate {
                @media (max-width: 850px) {
                    display: none;
                }
            }
            .search-btn {
                @media (max-width: 650px) {
                    display: none;
                }
            }
            // Hamburger animation
            button.hamburger {
                background-color: inherit;
                border: none;
                outline: none;
                margin: 0;
            }
            span {
                display: inline-block;
                a {
                    display: block;
                    color: $white;
                    padding: 23px 15px;
                }
                &.search-btn {
                    a {
                        i {
                            position: relative;
                            top: 2px;
                            margin-right: 6px;
                        }
                    }
                }
                &.renew-btn,
                &.renew-btn-alternate {
                    a {
                        padding-right: 23px;
                    }
                }
                &.subscribe-btn,
                &.renew-btn,
                &.gift-btn,
                &.subscribe-btn-alternate {
                    background: $sunburst-yellow;
                    display: inline-block;
                    a {
                        display: block;
                        color: $black;
                        padding: 23px;
                        @media (max-width: 600px) {
                            padding: 11px 10px;
                        }
                    }
                }
                &.subscribe-btn-alternate,
                &.renew-btn-alternate,
                &.gift-btn-alternate {
                    background: $black;
                    display: inline-block;
                    @media (max-width: 1000px) {
                        display: none;
                    }
                    a {
                        display: block;
                        color: $white;
                        padding: 23px 15px;
                        @media (max-width: 600px) {
                            padding: 11px 10px;
                        }
                    }
                }
            }

            .hamMenu {
                padding: 7px 0;
                cursor: pointer;

                @media (max-width: 650px) {
                    width: auto;
                    padding: 8px 0 5px;
                    border: none;
                }

                #mobile-icon {
                    width: 21px;
                    height: 13px;
                    margin: 0 20px;
                    position: relative;
                    transform: rotate(0deg);
                    transition:
                        all 0.5s ease-in-out,
                        top 0;
                    transition: top 0;
                    cursor: pointer;
                    @media (max-width: 450px) {
                        margin: 0 5px;
                    }
                    p {
                        color: $white;
                        position: relative;
                        left: 32px;
                        margin: 0;
                        top: -3px;

                        @media (max-width: 650px) {
                            display: none;
                        }
                    }
                    span {
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: $white;
                        border-radius: 9px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0deg);
                        transition: 0.25s ease-in-out;
                    }
                    span:nth-child(1) {
                        top: 0px;
                    }
                    span:nth-child(2),
                    span:nth-child(3) {
                        top: 6px;
                    }
                    span:nth-child(4) {
                        top: 12px;
                    }
                }

                &.open {
                    #mobile-icon span:nth-child(1) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                    #mobile-icon span:nth-child(2) {
                        transform: rotate(45deg);
                    }
                    #mobile-icon span:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                    #mobile-icon span:nth-child(4) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .navigation {
        width: 330px;
        // height: 100%;
        top: 64px;
        left: -200vw;
        bottom: 0px;
        background: $black;
        transform: translate3d(0px, 0px, 0px);
        transition: left cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
        position: fixed;
        overflow-y: scroll;
        z-index: 6;
        // Hide scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
        @media (max-width: 600px) {
            width: 100vw;
            height: 100vh;
            top: 44px;
        }

        &.open {
            left: 0;
        }

        nav {
            background: $black;
            box-sizing: border-box;
            outline: none;
            position: relative;
            z-index: 9;

            @media (max-width: 600px) {
                padding-bottom: 0;
            }

            .circulation {
                padding-top: 5px;
            }
            .three {
                padding-bottom: 300px;
            }
            i.fa-times {
                font-size: 15px;
                color: #ccc;
                position: absolute;
                right: 6px;
                top: 6px;
                cursor: pointer;
                z-index: 9;
            }

            .navWrap.search-wrapper {
                padding: 4px 20px 1rem 20px;
                position: relative;

                .search-form {
                    display: block;

                    input {
                        width: 99%;
                        padding: 10px 15px;
                        background-color: $gray;
                        border: none;
                        color: $white;
                        font-size: 0.9rem;
                        font-family: $proxima;
                        font-weight: 700;
                        outline: none;
                        box-sizing: border-box;

                        &::placeholder {
                            color: $white;
                            opacity: 1;
                        }
                    }

                    button {
                        width: auto;
                        padding: 0;
                        background-color: $gray;
                        border: none;
                        color: #fff;
                        font-size: 0.9rem;
                        position: absolute;
                        top: 0;
                        right: 34px;
                        cursor: pointer;
                    }
                }
            }

            .navWrap {
                padding: 0 20px;
                button {
                    &.subscribe-btn,
                    &.gift-btn,
                    &.renew-btn {
                        display: block;
                        background: $sunburst-yellow;
                        border: 0;
                        width: 100%;
                        padding: 0;
                        font-family: $proxima;
                        font-weight: 700;
                        margin-top: 10px;
                        color: $black;
                        a {
                            color: $black;
                        }
                    }
                    &.subscribe-btn-alternate,
                    &.gift-btn-alternate,
                    &.renew-btn-alternate {
                        display: block;
                        background: $white;
                        border: 0;
                        width: 100%;
                        padding: 0;
                        font-family: $proxima;
                        font-weight: 700;
                        margin-top: 15px;
                        a {
                            color: $black;
                        }
                    }
                }
                &.menu {
                    border-top: 1px solid $divider;
                    margin: 0 20px;
                    margin-top: 40px;
                    padding: 0;
                    p {
                        color: $white;
                        font-family: $proxima;
                        font-weight: 700;
                        font-size: 0.95rem;
                        margin-bottom: 0;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0;
                    position: relative;
                    li {
                        display: block;
                        position: relative;
                        a {
                            color: $white;
                            font-family: $proxima;
                            font-weight: 700;
                            font-size: 1.2rem;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.alert-banner {
    width: 100%;
    margin-top: 0;
    padding: 10px 20px;
    background: $sunburst-yellow;
    font-family: $proxima;
    text-align: center;
    position: relative;
    box-sizing: border-box;

    p {
        padding: 0;
        margin: 0;
    }

    .fa-circle-xmark {
        font-size: 20px;
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
        @media (max-width: 600px) {
            top: -7px;
            right: 4px;
            z-index: 9999;
        }
    }
}
